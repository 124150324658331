
import Vue from "vue";
import { User } from "@/models/user";
import moment from "moment";
import i18n from "@/i18n";

export default Vue.extend({
  name: "UsersList",
  data: () => ({
    expanded: [] as User[],
    singleExpand: true,
    isFormValid: true,
    isLoading: true,
    dialogApprove: false,
    dialogDisapprove: false,
    dialogDelete: false,
    search: "",
    role: 0,
    tempUsersList: [] as User[],
    editedIndex: -1,
    editedItem: {} as User,
    defaultItem: {} as User,
    rules: {
      select: [(v: string) => !!v || i18n.t("rules.required")],
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: i18n.t("basic.email"),
          value: "email",
          width: "30%",
        },
        {
          text: i18n.t("basic.role"),
          value: "role.name_" + i18n.locale,
          width: "10%",
        },
        // {
        //   text: i18n.t("basic.fullname"),
        //   value: "fullName",
        //   // width: "300px"
        // },
        // {
        //   text: i18n.t("basic.organization"),
        //   value: "organization",
        //   // width: "15%",
        // },
        {
          text: i18n.t("basic.country"),
          value: "organization.country.name_" + i18n.locale,
          width: "17%",
        },
        // {
        //   text: i18n.t("basic.status"),
        //   value: "isActive",
        //   align: "center",
        //   // width: "10%"
        // },
        {
          text: i18n.t("basic.approved"),
          value: "isApproved",
          align: "center",
          // width: "10%"
        },
        {
          text: i18n.t("basic.createdon"),
          value: "creationDate",
          // width: "15%"
        },
        {
          text: i18n.t("basic.lastlogin"),
          value: "lastLoginDate",
          width: "130px",
        },
        {
          text: i18n.t("basic.actions"),
          value: "actions",
          sortable: false,
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    users() {
      return this.$store.getters["user/getUsers"];
    },
    roles() {
      return this.$store.getters["user/getRoles"];
    },
  },
  methods: {
    resetValidation() {
      let refForm: any = this.$refs.form;
      if (refForm != undefined) refForm.resetValidation();
    },
    async fetchUsers() {
      await this.$store.dispatch("user/fetchUsers");
    },
    async fetchRoles() {
      this.isLoading = true;
      await this.$store.dispatch("user/fetchRoles").then((response) => {
        this.isLoading = false;
      });
    },
    approveItem(item: any) {
      this.editedIndex = this.tempUsersList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },
    closeApprove() {
      this.dialogApprove = false;
      this.resetValidation();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.role = 0;
      });
    },
    sendApprove() {
      let refForm: any = this.$refs.form;
      if (refForm != undefined && refForm.validate()) {
        this.approve(this.tempUsersList[this.editedIndex].key, this.role).then(
          (response) => {
            this.fetchUsers();
            this.closeApprove();
          }
        );
      }
    },
    disapproveItem(item: User) {
      this.editedIndex = this.tempUsersList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDisapprove = true;
    },
    closeDisapprove() {
      this.dialogDisapprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    disapproveItemConfirm() {
      this.disapprove(this.tempUsersList[this.editedIndex].key).then(
        (response) => {
          this.fetchUsers();
          this.closeDisapprove();
        }
      );
    },
    deleteItem(item: User) {
      this.editedIndex = this.tempUsersList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItemConfirm() {
      this.delete(this.tempUsersList[this.editedIndex].key).then((response) => {
        this.fetchUsers();
        this.closeDelete();
      });
    },
    async approve(key: string, roleId: number) {
      await this.$store.dispatch("user/userApprove", {
        culture: "en", //TODO: Change when users will have language in their settings
        data: {
          key: key,
          isApproved: true,
          roleId: roleId,
        },
      });
    },
    async disapprove(key: string) {
      await this.$store.dispatch("user/userApprove", {
        culture: "en", //TODO: Change when users will have language in their settings
        data: {
          key: key,
          isApproved: false,
        },
      });
    },
    async delete(key: string) {
      await this.$store.dispatch("user/userDelete", key);
    },
  },
  filters: {
    moment: function (date: string) {
      return moment(date).format("MM/DD/YYYY");
      //return moment(date).format("MMMM Do YYYY");
    },
    momentFromNow: function (date: string) {
      moment.locale(i18n.locale);
      if (date == null) return i18n.t("basic.never");
      return moment.utc(date).fromNow();
    },
  },
  watch: {
    users: {
      handler(res) {
        this.tempUsersList = [...res];
      },
    },
    dialogApprove(val) {
      val || this.closeApprove();
    },
    dialogDisapprove(val) {
      val || this.closeDisapprove();
    },
  },
  created() {
    this.fetchUsers();
    // this.fetchCountries();
    this.fetchRoles();
    console.log(i18n.locale);
  },
});
